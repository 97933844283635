// src/Components/HashGenerator.js

import React, { useState, useContext } from 'react';
import { TextField, Button, Box, Autocomplete } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { searchContext } from '../Context/Context';

// Predefined list of URLs
const predefinedUrls = [
    'https://editor.ayakawaii.com',
    'https://vid.ayakawaii.com',
    'https://kink.ayakawaii.com',
    // Add more predefined URLs as needed
];

const HashGenerator = () => {
    const [fullUrl, setFullUrl] = useState('');
    const [generatedUrl, setGeneratedUrl] = useState('');

    const { setHash } = useContext(searchContext); // Access setHash from context

    // Handle changes from the Autocomplete component
    const handleFullUrlChange = (event, newValue) => {
        if (typeof newValue === 'string') {
            setFullUrl(newValue);
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setFullUrl(newValue.inputValue);
        } else {
            setFullUrl(newValue || '');
        }
    };

    const handleSendToFilter = () => {
        if (generatedUrl) {
            const hash = generatedUrl.split('/').pop(); // Get hash after last slash
            setHash(hash);
        }
    };

    const handleGenerateUrl = async () => {
        try {
            const response = await fetch(`/api/hashgen?fullUrl=${encodeURIComponent(fullUrl)}`);
            if (response.ok) {
                const data = await response.json();
                setGeneratedUrl(data.url); // Assuming the response has a 'url' property

                // Copy generated URL to clipboard
                navigator.clipboard.writeText(data.url)
                    .then(() => {
                        console.log('URL copied to clipboard');
                        alert('Shareable URL copied to clipboard:\n' + data.url);
                    })
                    .catch((error) => {
                        console.error('Failed to copy URL:', error);
                    });
            } else {
                console.error('Error generating URL:', response.status);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('Error generating URL:', error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', p: 2, bgcolor: '#f5f5f5', gap: 2 }}>
            {/* Autocomplete for predefined URLs with free input */}
            <Autocomplete
                freeSolo
                options={predefinedUrls}
                filterOptions={(options, params) => {
                    const filtered = options.filter((option) =>
                        option.toLowerCase().startsWith(params.inputValue.toLowerCase())
                    );
                    return filtered;
                }}
                onChange={handleFullUrlChange}
                onInputChange={handleFullUrlChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Enter Full URL"
                        variant="outlined"
                        size="small"
                        value={fullUrl}
                    />
                )}
                sx={{ flexGrow: 1, minWidth: 240 }}
            />

            {/* Button to generate URL */}
            <Button variant="contained" onClick={handleGenerateUrl}>
                Generate URL
            </Button>

            {/* Button to send to filter */}
            <Button variant="contained" onClick={handleSendToFilter}>
                Send to Filter
            </Button>

            {/* Display generated URL with copy functionality */}
            <TextField
                label="Generated URL"
                variant="outlined"
                size="small"
                value={generatedUrl}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <ContentCopyIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (generatedUrl) {
                                    navigator.clipboard.writeText(generatedUrl)
                                        .then(() => {
                                            console.log('URL copied to clipboard');
                                        })
                                        .catch((error) => {
                                            console.error('Failed to copy URL:', error);
                                        });
                                }
                            }}
                        />
                    ),
                }}
                sx={{ minWidth: 240 }}
            />
        </Box>
    );
};

export default HashGenerator;
