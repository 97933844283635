import { useState, useContext, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  styled,
} from '@mui/material';
import { searchContext } from '../Context/Context';
import HeadTable from './HeadTable';
import TablesCell from './TablesCell';
import { COLUMN_MAPPING, DETAILED_COLUMN_MAPPING } from './Columns';

const StyledTableCell = styled(TableCell)({ // Use TableCell, not TablesCell
  backgroundColor: '#F5F5F5',
  color: '#000',
  textAlign: 'left',
  borderRight: '1px solid rgba(224, 224, 224, 1);',
});

const StickyHeadTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fingerprintData, setFingerprintData] = useState([]);
  const { searchTerm, checked } = useContext(searchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ignore = false; // To prevent updating state after component unmounts
    const fetchData = async () => {
      if (searchTerm && !ignore) { // Only fetch if searchTerm exists
        setIsLoading(true);
        setError(null);

        try {
          const response = await fetch(`/api/search?${searchTerm}`, {
            method: 'GET',
            headers: {
              'X-Ayaka-Magic': 'GZe3k5zig2jyQ8MSMyBa',
            },
          });
          if (response.ok) {
            const data = await response.json();
            if (!ignore) {
              setFingerprintData(data);
            }
          } else {
            if (!ignore) {
              setError(`Error fetching  ${response.status}`);
            }
          }
        } catch (error) {
          if (!ignore) {
            setError(`Error fetching  ${error.message}`);
          }
        } finally {
          if (!ignore) {
            setIsLoading(false);
          }
        }
      } else {
        if (!ignore) {
          setFingerprintData([]);
        }
      }
    };

    fetchData();
    return () => {
      ignore = true; // Set flag on unmount to cancel pending requests
    };
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', mt: 3 }}>
      <TableContainer sx={{ maxHeight: 550 }}>
        <HeadTable />
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ backgroundColor: '#F7F8F9' }}>
            <TableRow>
              {Object.keys(COLUMN_MAPPING).map((key) => (
                <StyledTableCell>{COLUMN_MAPPING[key]}</StyledTableCell>
              ))}{checked && (Object.keys(DETAILED_COLUMN_MAPPING).map((key) => (
                <StyledTableCell>{DETAILED_COLUMN_MAPPING[key]}</StyledTableCell>
              )))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && ( // Show loading indicator
              <TableRow>
                <StyledTableCell colSpan={15} align="center">
                  Loading...
                </StyledTableCell>
              </TableRow>
            )}
            {error && ( // Show error message
              <TableRow>
                <StyledTableCell colSpan={15} align="center">
                  {error}
                </StyledTableCell>
              </TableRow>
            )}
            {!isLoading && !error && fingerprintData.length === 0 && ( // Empty state
              <TableRow>
                <StyledTableCell colSpan={15} align="center">
                  No data available.
                </StyledTableCell>
              </TableRow>
            )}
            {!isLoading &&
              !error &&
              fingerprintData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TablesCell key={index} row={row} />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={fingerprintData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default StickyHeadTable;