import { TableCell, styled, TableRow } from '@mui/material'
import { useContext } from 'react'
import { searchContext } from '../Context/Context'
import { COLUMN_MAPPING, DETAILED_COLUMN_MAPPING } from './Columns';


const StyledTableCellInner = styled(TableCell)({
  textAlign: 'left',
  borderRight: '1px solid rgba(224, 224, 224, 1)',
})

const TablesCell = ({ row }) => {
  const { checked } = useContext(searchContext); // Get the checked state

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {Object.keys(COLUMN_MAPPING).map((key) => (
          <StyledTableCellInner key={key} component="th" scope="row">
            {key === 'vpn' ? (
              row[key] ? 'Yes' : 'No'
            ) : key === 'timestamp' ? (
              // Format the timestamp using Intl.DateTimeFormat
              new Date(row[key]).toLocaleString()
            ) : (
              row[key]
            )}
          </StyledTableCellInner>
        ))}{checked && (
          Object.keys(DETAILED_COLUMN_MAPPING).map((key) => (
            <StyledTableCellInner key={key} component="th" scope="row">
              {row[key]}
            </StyledTableCellInner>
          )))}
      </TableRow>
    </>
  );
};

export default TablesCell
