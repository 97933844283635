export const COLUMN_MAPPING = {
    hash: 'Hash',
    fullUrl: 'Full URL',
    timestamp: 'Time',
    visitorId: 'Visitor ID',
    vpn: 'Behind VPN',
    ip: 'IP Address',
    city: 'IP City',
    region: 'IP Region',
    country: 'IP Country',
    continent: 'IP Continent',
    timezone: 'IP Timezone',
    browserTimezone: 'System Timezone',
    acceptLanguages: 'System Languages',
    // webGlGpu: 'System GPU',
};

export const DETAILED_COLUMN_MAPPING = {
    webGlGpu: 'System GPU',
};