import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Filters from './Components/Filters';
import Tables from './Components/Tables';
import HashGenerator from './Components/HashGenerator';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SearchContextProvider } from './Context/Context';

const App = () => {
  return (
    <div className='App'>
      <SearchContextProvider>
        <Router>
          <HashGenerator />
          <Box>
            <Grid container columns={{ xs: 1, sm: 5, md: 8 }}>
              <Grid item xs={2} sm={2} md={2}>
                <Routes>
                  <Route path="/:hash" element={<Filters />} />
                  <Route path="/" element={<Filters />} />
                </Routes>
              </Grid>
              <Grid item xs={2} sm={3} md={6}>
                <Tables />
              </Grid>
            </Grid>
          </Box>
        </Router>
      </SearchContextProvider>
    </div>
  );
};

export default App;
