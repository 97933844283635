import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  TextField,
  Stack,
  FormControl,
  Container,
  Divider,
  Button
} from '@mui/material';
import CountrySelector from './CountrySelector';
import { searchContext } from '../Context/Context';
import { useParams, useLocation } from 'react-router-dom';

const Filters = () => {
  const { hash: routeHash } = useParams();
  const location = useLocation();
  const { hash, setHash, setSearchTerm } = useContext(searchContext);
  const [fullUrl, setFullUrl] = useState('');
  const [visitorId, setVisitorId] = useState('');
  const [ip, setIp] = useState('');
  const [country, setCountry] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialHash = params.get('hash') || routeHash || '';
    const initialFullUrl = params.get('fullUrl') || '';
    const initialVisitorId = params.get('visitorId') || '';
    const initialIp = params.get('ip') || '';
    const initialCountry = params.get('country') || '';

    if (initialHash) setHash(initialHash);
    if (initialFullUrl) setFullUrl(initialFullUrl);
    if (initialVisitorId) setVisitorId(initialVisitorId);
    if (initialIp) setIp(initialIp);
    if (initialCountry) setCountry(initialCountry);

    if (initialHash || initialFullUrl || initialVisitorId || initialIp || initialCountry) {
      const searchParams = new URLSearchParams();
      if (initialHash) searchParams.append('hash', initialHash);
      if (initialFullUrl) searchParams.append('fullUrl', initialFullUrl);
      if (initialVisitorId) searchParams.append('visitorId', initialVisitorId);
      if (initialIp) searchParams.append('ip', initialIp);
      if (initialCountry) searchParams.append('country', initialCountry);
      searchParams.append('_', Date.now());
      const queryString = searchParams.toString();
      setSearchTerm(queryString);
    }
  }, [location.search, routeHash, setHash, setSearchTerm]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const searchParams = new URLSearchParams();
    if (hash) searchParams.append('hash', hash);
    if (fullUrl) searchParams.append('fullUrl', fullUrl);
    if (visitorId) searchParams.append('visitorId', visitorId);
    if (ip) searchParams.append('ip', ip);
    if (country) searchParams.append('country', country);
    searchParams.append('_', Date.now());

    const queryString = searchParams.toString();
    setSearchTerm(queryString);

    const newUrl = `/${hash}?${queryString}`;
    window.history.pushState(null, '', newUrl);
  };

  // Function to create shareable URL
  const createShareableUrl = () => {
    const shareParams = new URLSearchParams();
    if (hash) shareParams.append('hash', hash);
    if (fullUrl) shareParams.append('fullUrl', fullUrl);
    if (visitorId) shareParams.append('visitorId', visitorId);
    if (ip) shareParams.append('ip', ip);
    if (country) shareParams.append('country', country);

    const baseUrl = window.location.origin; // Get the base URL of the site
    const shareableUrl = `${baseUrl}/?${shareParams.toString()}`;
    navigator.clipboard.writeText(shareableUrl).then(() => {
      alert('Shareable URL copied to clipboard:\n' + shareableUrl);
    });
  };

  return (
    <React.Fragment>
      <Container fixed sx={{ pt: 3 }}>
        <Box
          sx={{ py: 3 }}
          style={{
            backgroundColor: '#fff',
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            align="center"
            style={{ textTransform: 'uppercase', paddingBottom: 22 }}
          >
            Fingerprint Mana
          </Typography>
          <Divider />
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ p: 1 }}>
              <TextField
                inputRef={inputRef}
                id="hash"
                label="Hash"
                variant="standard"
                value={hash}
                onChange={(e) => setHash(e.target.value)}
                sx={{ mx: '20px', mb: 1 }}
              />
              <TextField
                id="fullUrl"
                label="Full URL"
                variant="standard"
                value={fullUrl}
                onChange={(e) => setFullUrl(e.target.value)}
                sx={{ mx: '20px', mb: 1 }}
              />
              <TextField
                id="visitorId"
                label="Visitor ID"
                variant="standard"
                value={visitorId}
                onChange={(e) => setVisitorId(e.target.value)}
                sx={{ mx: '20px', mb: 1 }}
              />
              <TextField
                id="ip"
                label="IP Address"
                variant="standard"
                value={ip}
                onChange={(e) => setIp(e.target.value)}
                sx={{ mx: '20px', mb: 1 }}
              />
            </FormControl>

            <CountrySelector onSelect={(code) => setCountry(code)} />

            <Stack
              direction='column'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Button variant="contained" type="submit">
                Search
              </Button>
              <Button variant="outlined" onClick={createShareableUrl}>
                Share Fields as URL
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Filters;
